
import './App.css';

import React, { useEffect, useState } from 'react';

import { Pie,Scatter,Line } from 'react-chartjs-2';
import { Chart,ArcElement,
  CategoryScale,
  LinearScale,LineController,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend } from 'chart.js'

import 'chartjs-adapter-moment';

Chart.register(ArcElement,TimeScale,LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  );

async function getMempoolRecent() {
  const response = await fetch('https://ord-mempool-be.bot.win/get_recent');
  const responseJson = await response.json()
  let mintMapPrecent = {
    runes: {},
    inscribe: {}
  };
  let mintMapHistory = {
    runes: [],
    inscribe: []
  };
  const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
  
  for (const mempoolTxInfo of responseJson.data) {
    const fee = mempoolTxInfo[5];
    const type = mempoolTxInfo[3];
    const name = mempoolTxInfo[2];
    const txTime = mempoolTxInfo[1];

    if ('mint_runes' === type) {
      if (!(name in mintMapPrecent.runes))
        mintMapPrecent.runes[name] = 0;

      mintMapPrecent.runes[name] += 1;

      const txTimetick = new Date(txTime).getTime() - timezoneOffsetInMinutes * 60 * 1000;
      mintMapHistory.runes.push([name,fee,new Date(txTimetick).toISOString()]);
    } else if ('mint_inscribe' === type) {
      if (!(name in mintMapPrecent.inscribe))
        mintMapPrecent.inscribe[name] = 0;

      mintMapPrecent.inscribe[name] += 1;

      const txTimetick = new Date(txTime).getTime() - timezoneOffsetInMinutes * 60 * 1000;
      mintMapHistory.inscribe.push([name,fee,new Date(txTimetick).toISOString()]);
    }
  }

  return [mintMapPrecent,mintMapHistory];
}

async function getMempoolFeeHistory24hAndNextBlock() {
  const responseHeight = await fetch('https://mempool.space/api/blocks/tip/height');
  const responseHeightData = parseInt(await responseHeight.text());
  const currentBlock = responseHeightData;
  const last15Block = responseHeightData - 15;
  const responseHistoryCurrentBlock = await fetch(`https://mempool.space/api/v1/blocks/${currentBlock}`);
  const responseHistoryCurrentBlockJson = await responseHistoryCurrentBlock.json();
  const responseHistoryLast15Block = await fetch(`https://mempool.space/api/v1/blocks/${last15Block}`);
  const responseHistoryLast15BlockJson = await responseHistoryLast15Block.json();
  const responseMempool = await fetch('https://mempool.space/api/v1/fees/mempool-blocks');
  const responseMempoolJson = await responseMempool.json();
  const staticBlockHistory = responseHistoryCurrentBlockJson.concat(responseHistoryLast15BlockJson);
  const currentTime = new Date().getTime();
  let gasHistory = [];
  
  const nextBlockFee = responseMempoolJson[0];

  gasHistory.push({x:new Date(currentTime).toISOString(),y:nextBlockFee.feeRange[0],text:'nextBlock'});

  for (const blockInfo of staticBlockHistory) {
    const blockTime = blockInfo.timestamp * 1000;
    const blockBaseFee = blockInfo.extras.feeRange[0];
    const blockHeight = blockInfo.height;
    
    if ((currentTime - 3 * 3600 * 1000) >= blockTime)
      break;

    gasHistory.push({x:new Date(blockTime).toISOString(),y:blockBaseFee,text:blockHeight});
  }

  return gasHistory;
}

const randomRGBColorByLine = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return [`rgb(${r}, ${g}, ${b}, 0.2)`,`rgb(${r}, ${g}, ${b}, 1)`];
};

const randomRGBColorByPie = () => {
  const r = Math.floor(Math.random() * 256).toString(16).toUpperCase();
  const g = Math.floor(Math.random() * 256).toString(16).toUpperCase();
  const b = Math.floor(Math.random() * 256).toString(16).toUpperCase();
  return `#${r}${g}${b}`;
};

const MapStatic = (elementProp) =>  {
  const mapType = elementProp.label;
  let mapLabelData = [];
  let mapLabelText = [];
  let mapLabelColor = [];
  let mempoolData = null;
  let showTitle = '';

  if ('runes' === mapType) {
    mempoolData = elementProp.data.runes;
    showTitle = 'Runes';
  } else if ('inscribe' === mapType) {
    mempoolData = elementProp.data.inscribe;
    showTitle = 'Inscribe';
  }

  for (const key in mempoolData) {
    const txCount = mempoolData[key];
    mapLabelData.push(txCount);
    mapLabelText.push(key);
    mapLabelColor.push(randomRGBColorByPie());
  }

  let mapLabel = mapLabelText.map((label,index) =>`${label}: ${mapLabelData[index]}`);

  const data = {
    labels: mapLabel,
    datasets: [
      {
        data: mapLabelData,
        backgroundColor: mapLabelColor,  /// ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: mapLabelColor ///['#FF6384', '#36A2EB', '#FFCE56']
      }
    ]
  };
  const options = {
    legend: { display: true, position: "right" },
    datalabels: {
      display: true,
      color: "black",
    },
    tooltips: {
      backgroundColor: "#5a6e7f",
    },
  };

  return (
    <div style={{ width: '400px', height: '400px'}}>
      <h2>Ordinal {showTitle} In Mempool</h2>
      <Pie data={data} options={options} />
    </div>
  );
}

const GasMonitor = (elementProp) =>  {
  const mapType = elementProp.label;
  let mempoolData = null;
  let showTitle = '';

  if ('runes' === mapType) {
    mempoolData = elementProp.data.runes;
    showTitle = 'Runes';
  } else if ('inscribe' === mapType) {
    mempoolData = elementProp.data.inscribe;
    showTitle = 'Inscribe';
  }

  if (!mempoolData)
    return;

  let preprocessDataset = {};

  for (const txInfo of mempoolData) {
    const name = txInfo[0];
    const fee = txInfo[1];
    const timeDate = txInfo[2];

    if (!(name in preprocessDataset))
      preprocessDataset[name] = [];

      preprocessDataset[name].push({x:timeDate,y:fee});
  }

  let datasets = [];

  for (const key in preprocessDataset) {
    const [backgroundColor,borderColor] = randomRGBColorByLine();
    datasets.push({
      label: key,
      data: preprocessDataset[key],
      backgroundColor,
      borderColor,
      borderWidth: 1
    });
  }

  datasets.push({
    label: 'HistoryGas',
    type: 'line',
    data: elementProp.dataGas,
    backgroundColor: 'rgb(182,00,35,1)',
    borderColor: 'rgb(182,00,35,1)',
    borderWidth: 2
  });

  console.log('GasMonitor',datasets);
  
  const options = {
    scales: {
      x: {
        type: 'time',
      },
    },
  };

  const scalesData = {
    datasets
  };
  
  return (
    <div>
      <h2>Ordinal {showTitle} Mempool Gas Monitor</h2>
      <Scatter data={scalesData} options={options} />
    </div>
  );
}

function App() {
  const [mintMapPrecent,SetMintMapPrecent] = useState({});
  const [mintMapHistory,SetMintMapHistory] = useState({});
  const [gasHistory,SetGasHistory] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const [__mintMapPrecent,__mintMapHistory] = await getMempoolRecent();
      const __gasHistory = await getMempoolFeeHistory24hAndNextBlock()

      SetMintMapPrecent(__mintMapPrecent);
      SetMintMapHistory(__mintMapHistory);
      SetGasHistory(__gasHistory);
      console.log(mintMapHistory);
      console.log(__gasHistory);
    }

    fetchData();
  },[]);

  return (
    <div className="App">
      <h1>Ordinal Gas Monitor</h1>
      <div className="container" style={{ marginBottom: '40px' }}>
        <div className="left-panel">
          <MapStatic label='runes' data={mintMapPrecent}></MapStatic>
        </div>
        <div className="right-panel">
          <MapStatic label='inscribe' data={mintMapPrecent}></MapStatic>
        </div>
      </div>
      <div className="container" style={{ marginBottom: '40px' }}>
        <div className="left-panel">
          <GasMonitor label='runes' data={mintMapHistory} dataGas={gasHistory}></GasMonitor>
        </div>
        <div className="right-panel">
          <GasMonitor label='inscribe' data={mintMapHistory} dataGas={gasHistory}></GasMonitor>
        </div>
      </div>
    </div>
  );
}

export default App;
